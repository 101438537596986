import type { Dictionary } from '@/types';
import { isBlank } from '@/utils/strings';

type SortableArrayItem = Dictionary<any>;

type MoveIndex = number | null;

export function sortByKey<T extends SortableArrayItem>(
  arr: T[],
  key: string,
  order = 1
) {
  return arr.sort((a, b) => {
    let obj1 = order === 1 ? a : b;
    let obj2 = order === 1 ? b : a;
    if (obj1[key] > obj2[key]) return -1;
    if (obj2[key] > obj1[key]) return 1;
    return 0;
  });
}

export function sortAlphabeticalByKey<T extends SortableArrayItem>(
  arr: T[],
  key = 'name',
  order = 1,
  caseSensitive = false
): T[] {
  return sortAlphabeticalByObjectValue(
    arr,
    (item) => item[key],
    order,
    caseSensitive
  );
}

export function sortAlphabeticalByObjectValue<T extends SortableArrayItem>(
  arr: T[],
  getValue: (item: T) => string,
  order = 1,
  caseSensitive = false
): T[] {
  const sensitivity = caseSensitive ? 'base' : 'variant';
  const result = [...arr].sort((a, b) =>
    getValue(a).localeCompare(getValue(b), [...navigator.languages], {
      sensitivity,
    })
  );
  if (order === -1) result.reverse();
  return result;
}

export function sortByCreatedAt<T extends SortableArrayItem>(
  arr: T[],
  order = 1
) {
  return sortByKey(arr, 'createdAt', order);
}

export function sortByUpdatedAt<T extends SortableArrayItem>(
  arr: T[],
  order = 1
) {
  return sortByKey(arr, 'updatedAt', order);
}

export function sortByStartsAt<T extends SortableArrayItem>(
  arr: T[],
  order = 1
) {
  return sortByKey(arr, 'startsAt', order);
}

export function sortByDueAt<T extends SortableArrayItem>(arr: T[], order = 1) {
  return sortByKey(arr, 'dueAt', order);
}

export function sortAlphabetical(arr: string[], order = 1) {
  const result = [...arr].sort();
  if (order === -1) result.reverse();
  return result;
}

export function sortNumerical(arr: number[], order = 1) {
  const result = [...arr].sort((a, b) => a - b);
  if (order === -1) result.reverse();
  return result;
}

export function includesValue(
  arr: Record<string, any>[],
  key: string,
  value: any
) {
  return arr.some((obj) => obj[key] === value);
}

export function arrayMove<T>(array: T[], fromIndex: number, toIndex: number) {
  // Inspired by https://www.npmjs.com/package/array-move
  const result = [...array];
  const startIndex = fromIndex < 0 ? result.length + fromIndex : fromIndex;
  if (startIndex >= 0 && startIndex < result.length) {
    const endIndex = toIndex < 0 ? result.length + toIndex : toIndex;
    const [item] = result.splice(fromIndex, 1);
    result.splice(endIndex, 0, item);
  }
  return result;
}

export function addToArray<T>(arr: T[], item: T, index?: number) {
  if (index === undefined) return [...arr, item];
  return [...arr.slice(0, index), item, ...arr.slice(index)];
}

export function removeFromArray<T>(arr: T[], index: number) {
  return [...arr.slice(0, index), ...arr.slice(index + 1)];
}

export function removeDuplicates(arr: any[]) {
  return [...new Set(arr)];
}

export function mergeWithoutDuplicates<T>(...arrays: T[][]) {
  return [...new Set(([] as T[]).concat(...arrays))];
}

export function mergeWithoutDuplicatesByKey<T extends SortableArrayItem>(
  arr1: T[],
  arr2: T[],
  key = 'id'
) {
  return arr1.concat(
    arr2.filter((item) => !includesValue(arr1, key, item[key]))
  );
}

export function filterBlanks<T>(arr: (T | null | undefined)[]): T[] {
  return arr.filter((item): item is T => item !== null && item !== undefined);
}

export function filterOutValue<T>(arr: T[], value: T) {
  return arr.filter((item) => item !== value);
}

export function filterOutValues<T>(arr: T[], values: T[]) {
  // Remove any items that *are* present in `values`
  return arr.filter((item) => !values.includes(item));
}

export function filterInValues<T>(arr: T[], values: T[]) {
  // Remove any items that *aren't* present in `values`
  return arr.filter((item) => values.includes(item));
}

export function isEmpty(val: any[] | Dictionary<any> | string) {
  if (Array.isArray(val)) return val.length === 0;
  else if (typeof val === 'string') return isBlank(val);
  return Object.keys(val).length === 0;
}

export function mapToKey<T, K extends keyof T>(arr: T[], key: K): T[K][] {
  return arr.map((item) => item[key]);
}

export function mapToID<T extends { id: number }>(arr: T[]): number[] {
  return mapToKey(arr, 'id');
}

export function toObject<T extends Record<K, any>, K extends keyof T>(
  arr: T[],
  key: K
): Record<T[K], T> {
  return arr.reduce((a, b) => ({ ...a, [b[key]]: b }), {}) as Record<
    (typeof arr)[number][typeof key],
    any
  >;
}

export function hasMovedIndex(removedIndex: MoveIndex, addedIndex: MoveIndex) {
  return removedIndex !== null || addedIndex !== null;
}

export function isMoved(removedIndex: MoveIndex, addedIndex: MoveIndex) {
  return hasMovedIndex(removedIndex, addedIndex) && removedIndex !== addedIndex;
}
